import React from 'react';
import { Routes, Route } from 'react-router-dom';

import LoginRoutes from './login';
import ModulosRoute from './modulos';
import GestaoRoutes from './gestao';
import GestaoUnidadesRoutes from './gestaoUnidades';
import ModulosRoutes from './modulos';
import ChamadaPublicaRoutes from './processos/chamadaPublica';
import PreMatriculaRoutes from './processos/preMatricula';
import ConfiguracoesRoutes from './configuracoes';
import RemanejamentoRoutes from './processos/remanejamento';
import RelatorioRoutes from './relatorio';
import BilingueRoutes from './processos/bilingue';
import ServidoresRoute from './processos/servidores';
import DocenteRoutes from './docente';
import FichaAvaliacaoRoutes from './ficha-avaliacao';
import FamiliaConectadaRoutes from './familia-conectada';
import BibliotecaRoutes from './biblioteca';

const AppRoutes = () => (
  <Routes>
       {LoginRoutes()}
       {ModulosRoute()}
       {GestaoRoutes()}
       {GestaoUnidadesRoutes()}
       {ModulosRoutes()}
       {ChamadaPublicaRoutes()}
       {PreMatriculaRoutes()}
       {ConfiguracoesRoutes()}
       {RemanejamentoRoutes()}
       {RelatorioRoutes()}
       {BilingueRoutes()}
       {ServidoresRoute()}
       {DocenteRoutes()}
       {FichaAvaliacaoRoutes()}
       {FamiliaConectadaRoutes()}
       {BibliotecaRoutes()}
  </Routes>
);

export default AppRoutes;