import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import urlsConfiguracoes from "../../configuracoes/urlsConfiguracoes";
import { useMutation } from 'react-query';
import { createUnidade, updateUnidade } from "../../../services/unidades";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import removeEmpty from "../../../utils/removeEmpty";
import BaseConfiguracoes from "../../configuracoes/baseConfiguracoes";

const UnidadesCadastrarPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();

  const { mutate, isLoading } = useMutation(id ? updateUnidade : createUnidade, {
    onSuccess: (message) => {
      toast.success(message);
      navigate(urlsConfiguracoes.unidades);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar cadastrar um unidade, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
    mode: "onChange", defaultValues: {
      nome: '',
      sigla: '',
      endereco: {
        cep : '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: ''
      },
      contato: {
        telefone: '',
        email: ''
      },
      status: "1",
      unidadeLotacao: 0,
      unidadeLocacao: 0,
      nomeDiretor: '',
      inep: "",
    }
  });
  
  const onSubmit = (data) => {
    const formattedData = {
      nome: data?.nome.toUpperCase(),
      sigla: data?.sigla.toUpperCase(),
      endereco: upperCaseSimple(removeEmpty(data?.endereco)),
      contato: upperCaseSimple(removeEmpty(data?.contato)),
      status: data?.status,
      unidadeLotacao: data?.unidadeLotacao,
      unidadeLocacao: data?.unidadeLocacao,
      nomeDiretor: data?.nomeDiretor,
      inep: data?.inep,
    }
    if (id) {
      mutate({ id, data: formattedData });
    } else {
      mutate(formattedData);
    }
  };

  useMemo(() => {
    (async () => {
      if (state) {
        const { unidade } = state;
        if (id && unidade) {
          const { nome, sigla, endereco, contato, status, unidadeLotacao, unidadeLocacao, nomeDiretor, inep } = unidade;
          const { cep, logradouro, numero, complemento, bairro, cidade, estado } = endereco;
          const { telefone, email } = contato;
          setValue('nome', nome);
          setValue('sigla', sigla);
          setValue('endereco.cep', cep);
          setValue('endereco.logradouro', logradouro);
          setValue('endereco.numero', numero);
          setValue('endereco.complemento', complemento);
          setValue('endereco.bairro', bairro);
          setValue('endereco.cidade', cidade);
          setValue('endereco.estado', estado);
          setValue('contato.telefone', telefone);
          setValue('contato.email', email);
          setValue('status', status);
          setValue('unidadeLotacao', unidadeLotacao);
          setValue('unidadeLocacao', unidadeLocacao);
          setValue('nomeDiretor', nomeDiretor);
          setValue('inep', inep);
        }
      }
    })();
  }, [id, state, setValue]);

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Formulário Unidades'} itens={['Gestão', 'Secretaria', 'Unidades', 'Formulário']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">

                  <div className='row'>
                    <div className='col-10'>
                      <label htmlFor="nome" className="form-label">Nome</label>
                      <input type="text" className="form-control" id="nome"
                        {...register("nome", { required: true })} />
                      {errors.nome?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                    
                    <div className='col-2 '>
                      <label htmlFor="sigla" className="form-label">Sigla</label>
                      <input type="text" className="form-control" id="sigla"
                        {...register("sigla", { required: true })} />
                      {errors.sigla?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campoobrigatório!</div>}
                    </div>
                  </div>


                  <div className='row' style={{marginTop: 30}}>
                    <div className='col-3'>
                      <label htmlFor="cep" className="form-label">CEP</label>
                      <input type="text" className="form-control" id="cep"
                        {...register("endereco.cep", { required: true })} />
                      {errors.endereco?.cep?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                    <div className='col-7'>
                      <label htmlFor="logradouro" className="form-label">Logradouro</label>
                      <input type="text" className="form-control" id="logradouro"
                        {...register("endereco.logradouro", { required: true })} />
                      {errors.endereco?.logradouro?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                    <div className='col-2'>
                      <label htmlFor="numero" className="form-label">Número</label>
                      <input type="text" className="form-control" id="numero"
                        {...register("endereco.numero", { required: true })} />
                      {errors.endereco?.numero?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>


                  <div className='row' style={{marginTop: 30}}>
                  <div className='col-4'>
                      <label htmlFor="complemento" className="form-label">Complemento</label>
                      <input type="text" className="form-control" id="complemento"
                        {...register("endereco.complemento", { required: true })} />
                      {errors.endereco?.complemento?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                    <div className='col-3'>
                      <label htmlFor="bairro" className="form-label">Bairro</label>
                      <input type="text" className="form-control" id="bairro"
                        {...register("endereco.bairro", { required: true })} />
                      {errors.endereco?.bairro?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                    <div className='col-3'>
                      <label htmlFor="cidade" className="form-label">Cidade</label>
                      <input type="text" className="form-control" id="cidade"
                        {...register("endereco.cidade", { required: true })} />
                      {errors.endereco?.cidade?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                    <div className='col-2'>
                      <label htmlFor="estado" className="form-label">Estado</label>
                      <input type="text" className="form-control" id="estado"
                        {...register("endereco.estado", { required: true })} />
                      {errors.endereco?.estado?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>

                  <div className='row' style={{marginTop: 30}}>
                    <div className='col-4'>
                      <label htmlFor="telefone" className="form-label">Telefone</label>
                      <input type="text" className="form-control" id="telefone"
                        {...register("contato.telefone", { required: true })} />
                      {errors.contato?.telefone?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                    <div className='col-8'>
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="text" className="form-control" id="email"
                        {...register("contato.email", { required: true })} />
                      {errors.contato?.email?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>
                  </div>

                  <div className='row' style={{marginTop: 30}}>
                    <div className='col-4'>
                      <label htmlFor="status" className="form-label">Status</label>
                      <select className="form-select" id="status" {...register("status", { required: true })}>
                        <option value="1">ATIVO</option>
                        <option value="0">INATIVO</option>
                      </select>
                      {errors.status?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                    <div className='col-4'>
                      <label htmlFor="unidadeLotacao" className="form-label">Unidade de Exercício?</label>
                      <select className="form-select" id="unidadeLotacao" {...register("unidadeLotacao", { required: true })}>
                        <option value="1">SIM</option>
                        <option value="0">NÃO</option>
                      </select>
                      {errors.unidadeLotacao?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                    <div className='col-4'>
                      <label htmlFor="unidadeLocacao" className="form-label">Unidade Locação?</label>
                      <select className="form-select" id="status" {...register("unidadeLocacao", { required: true })}>
                       <option value="1">SIM</option>
                        <option value="0">NÃO</option>
                      </select>
                      {errors.unidadeLocacao?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                  </div>

                  <div className='row' style={{marginTop: 30}}>
                  <div className='col-6'>
                      <label htmlFor="nomeDiretor" className="form-label">Nome Diretor</label>
                      <input type="text" className="form-control" id="nomeDiretor"
                        {...register("nomeDiretor", { required: true })} />
                      {errors.nomeDiretor?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                    

                    <div className='col-6'>
                      <label htmlFor="inep" className="form-label">INEP</label>
                      <input type="text" className="form-control" id="inep"
                        {...register("inep", { required: false })} />
                      {errors.inep?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                    </div>

                  </div>

              </div>
              <div className='card-body text-end'>
                <button type='submit' className='btn btn-success' disabled={!isValid || isLoading}>
                  {isLoading ? 'enviando...' : id ? 'Atualizar' : 'Cadastrar'}
                </button>
              </div>

            </div>
          </div>
        </div>
      </form>
    </BaseConfiguracoes>
  )

};

export default UnidadesCadastrarPage;