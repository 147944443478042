const baseUrl = '/configuracoes';

const urlsConfiguracoes = {
    dashboard: baseUrl,
    unidades: `${baseUrl}/unidades`,
    unidadeAdicionar: `${baseUrl}/unidades/adicionar`,
    unidadeEditar: `${baseUrl}/unidades/editar/`,
    unidadeEditarId: `${baseUrl}/unidades/editar/:id`,
    escolaridades: `${baseUrl}/escolaridades`,
    escolaridadeAdicionar: `${baseUrl}/escolaridades/adicionar`,
    escolaridadeEditar: `${baseUrl}/escolaridades/editar/`,
    escolaridadeEditarId: `${baseUrl}/escolaridades/editar/:id`,
    turnos: `${baseUrl}/turnos`,
    turnoEditar: `${baseUrl}/turnos/editar/`,
    turnoAdicionar: `${baseUrl}/turnos/adicionar`,
    turnoEditarId: `${baseUrl}/turnos/editar/:id`,
    responsaveis: `${baseUrl}/responsaveis`,
    responsaveisAdicionar: `${baseUrl}/responsaveis/adicionar`,
    responsaveisEditar: `${baseUrl}/responsaveis/editar/`,
    responsaveisEditarId: `${baseUrl}/responsaveis/editar/:id`,
    cargos: `${baseUrl}/cargos`,
    cargoEditar: `${baseUrl}/cargos/editar/`,
    cargoAdicionar: `${baseUrl}/cargos/adicionar`,
    cargoEditarId: `${baseUrl}/cargos/editar/:id`,
    nacionalidades: `${baseUrl}/nacionalidades`,
    nacionalidadeAdicionar: `${baseUrl}/nacionalidades/adicionar`,
    nacionalidadeEditar: `${baseUrl}/nacionalidades/editar/`,
    nacionalidadeEditarId: `${baseUrl}/nacionalidades/editar/:id`,
    disciplinas: `${baseUrl}/disciplinas`,
    disciplinasAdicionar: `${baseUrl}/disciplinas/adicionar`,
    disciplinasEditar: `${baseUrl}/disciplinas/editar/`,
    disciplinasEditarId: `${baseUrl}/disciplinas/editar/:id`,
    distritos: `${baseUrl}/distritos`,
    distritosAdicionar: `${baseUrl}/distritos/adicionar`,
    distritosEditar: `${baseUrl}/distritos/editar/`,
    distritosEditarId: `${baseUrl}/distritos/editar/:id`,
    distritoAlocar: `${baseUrl}/distritos/alocar/`,
    distritoAlocarId: `${baseUrl}/distritos/alocar/:id`,
    bnncFundamental: `${baseUrl}/bncc/fundamental`,
    alunosExcluidos: `${baseUrl}/alunos/excluidos`,
    servidoresExcluidos: `${baseUrl}/servidores/excluidos`,


}

export default urlsConfiguracoes;