import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsBiblioteca from '../../pages/biblioteca/urlsBiblioteca';
import HomeBiblioteca from '../../pages/biblioteca/homeBiblioteca';
import BibliotecaPage from '../../pages/biblioteca';
import LivroAdicionarPage from '../../pages/biblioteca/adicionar';
import EmprestimosPage from '../../pages/biblioteca/emprestimos';

const defaultPermisson = false;

const BibliotecaRoutes = () => (
    <React.Fragment>
        <Route exact path={urlsBiblioteca.dashboard} element={ <HomeBiblioteca />}/>
        <Route exact path={urlsBiblioteca.livros} element={ <BibliotecaPage />}/>
        <Route exact path={urlsBiblioteca.adicionar} element={ <LivroAdicionarPage />}/>
        <Route exact path={urlsBiblioteca.emprestimo} element={ <EmprestimosPage />}/>
        
    </React.Fragment>
);

export default BibliotecaRoutes;
