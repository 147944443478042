import React from 'react';
import Breadcrumb from '../../../components/breadcrumb';
import BaseFamiliaConectada from '../baseFamiliaConectada';
import Table from '../../../components/table';
import { listarAvisos } from '../../../services/familia-conectada';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { Alert } from '@mui/material'

const FamiliaConectadaPais = () => {

    const BaseComponent = BaseFamiliaConectada;
    const BasePath = 'Família Conectada';
    const modulo = 'Pais';
    const componente = 'Listar Pais';
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [avisos, setAvisos] = React.useState([]);

    const alunoData = localStorage.getItem('aluno');
    const aluno = JSON.parse(alunoData);

    const { isLoading: avisosData, refetch: refetchAvisos } = useQuery(
        'getAvisos',
        () => listarAvisos(0, 0, aluno?.id, 0, 20),
        {
          enabled: true,
          retry: true,
          onSuccess: (data) => {
            setAvisos(data?.data.data);
          },
        }
      );

      const columns = useMemo(
        () => [
          { Header: '#', accessor: 'id' },
          { Header: 'Título', accessor: 'titulo' },
          { Header: 'Descrição', accessor: 'descricao' },
          {
            Header: 'Notificado Em',
            accessor: 'dataComunicacao',
            Cell: ({ row }) => row.original.dataComunicacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.dataComunicacao.split('T')[1].split('.')[0],
          },
          { Header: 'Enviado Por', accessor: 'servidorNome' },
          { Header: 'Aluno', accessor: 'alunoNome' },
          // {
          //   Header: 'Lido',
          //   accessor: 'lida',
          //   Cell: ({ row }) => (
          //     <span className={`badge bg-${row.original.lida ? 'success' : 'danger'}`}>
          //       {row.original.lida ? 'SIM' : 'NÃO'}
          //     </span>
          //   ),
          // },
        ],
        []
      );

    return (
        <BaseComponent>
        <Breadcrumb
          title={aluno?.nome}
          itens={[BasePath, modulo, componente]}
        />

<Alert variant="filled" severity="info" style={{marginBottom: 20, marginTop: 20}}>Senhores(as) responsáveis! <br></br><br></br>

Em breve será possível realizar o acompanhamento da frequência e situação escolar através desse portal.</Alert>
   {/* <h3 style={{marginBottom: 20}}>Avisos</h3> */}
<div className="row">
        <div className="col-12">
          <div className="card">
         
            <Table
              columns={columns}
              data={avisos || []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={avisos?.length}
            />
          </div>
        </div>
      </div>
        </BaseComponent>
    );
};

export default FamiliaConectadaPais;