import RiapCreche from "../riap/creche";
import urlsDocente from "./urlsDocente";

const linksDocente = (moduloDocenteGrupoGestaoEducacional, moduloDocenteGrupoAcademico, moduloDocenteGrupoDiario) => {
  return (
    {
      gestao_educacional: {
        id: 'gestao_educacional',
        text: 'GESTÃO EDUCACIONAL',
        type: 'title',
        visible: moduloDocenteGrupoGestaoEducacional == 1,
        itens: {
          dashboard: {
            id: 'dashboard',
            text: 'DASHBOARD',
            path: urlsDocente.dashboard,
            icon: 'dashboard',
            visible: moduloDocenteGrupoGestaoEducacional == 1,
          },
          calendario: {
            id: 'calendario',
            text: 'CALENDÁRIO',
            path: urlsDocente.calendario,
            icon: 'calendar_view_week',
            visible: true,
          },
 
          // notificacoes: {
          //   id: 'calendario',
          //   text: 'CALENDÁRIO',
          //   path: urlsDocente.calendario,
          //   icon: 'material-icons-two-tone',
          //   visible: false,
          // },
          noticias: {
            id: 'noticias',
            text: 'NOTÍCIAS',
            path: urlsDocente.noticias,
            icon: 'announcement',
            visible: false,
          },
      }
    },

      academico: {
        id: 'academico',
        text: 'ACADÊMICO',
        type: 'title',
        visible: moduloDocenteGrupoAcademico == 1,
        itens: {
          alunos: {
            id: 'alunos',
            text: 'ALUNOS',
            path: urlsDocente.alunos,
            icon: 'groups',
            visible: moduloDocenteGrupoAcademico == 1,
          },
          turmas: {
            id: 'turmas',
            text: 'TURMAS',
            path: urlsDocente.turmas,
            icon: 'class',
            visible: false,
          }
    }
},

    diario: {
      id: 'academico',
      text: 'DIÁRIO',
      type: 'title',
      visible: moduloDocenteGrupoDiario == 1,
      itens: {
        quadro: {
          id: 'quadro',
          text: 'QUADRO DE HORÁRIO',
          path: urlsDocente.quadroHorario,
          icon: 'calendar_view_week',
          visible: moduloDocenteGrupoDiario == 1,
        },
        // notas: {
        //   id: 'diario',
        //   text: 'NOTAS',
        //   path: urlsDocente.notas,
        //   icon: 'collections_bookmark',
        //   visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' && moduloDocenteGrupoDiario == 1,
        // },
        // faltas: {
        //     id: 'faltas',
        //     text: 'FALTAS',
        //     path: urlsDocente.faltas,
        //     icon: 'spellcheck',
        //     visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' && moduloDocenteGrupoDiario == 1,
        //   },
          // fichaAvaliacao: {
          //   id: 'fichas',
          //   text: 'FICHAS DE AVALIAÇÃO',
          //   path: urlsDocente.fichaAvaliacaoVisualizacao,
          //   icon: 'collections_bookmark',
          //   visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED' && moduloDocenteGrupoDiario == 1,
          // },
          boletim: {
            id: 'boletim',
            text: 'BOLETIM',
            path: urlsDocente.notasVisualizacao,
            icon: 'spellcheck',
            visible: false,
          },
          bnncFundamental:{
            id: 'bnnc-fundamentos',
            text: 'BNCC FUNDAMENTAL',
            path: urlsDocente.bnncFundamental,
            icon: 'collections_bookmark',
            visible: true,
          },
      },
    },
      fichaAvaliacao: {
        id: 'fichaAvaliacao',
        text: 'FICHA AVALIAÇÃO',
        type: 'title',
        visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED' && moduloDocenteGrupoDiario == 1,
        itens: {
          fichaAvaliacaoInfantil: {
            id: 'fichaAvaliacaoInfantil',
            text: 'INFANTIL',
            path: urlsDocente.fichaAvaliacaoInfantil,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED' && moduloDocenteGrupoDiario == 1,
          },
          fichaAvaliacaoPrimeiroAno: {
            id: 'fichaAvaliacaoPrimeiroAno',
            text: '1º ANO',
            path: urlsDocente.fichaAvaliacaoPrimeiroAno,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED' && moduloDocenteGrupoDiario == 1,
          },
          fichaAvaliacaoSegundoAno: {
            id: 'fichaAvaliacaoSegundoAno',
            text: '2º ANO',
            path: urlsDocente.fichaAvaliacaoSegundoAno,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED' && moduloDocenteGrupoDiario == 1,
          },
          fichaAvaliacaoTerceiroNonoAno: {
            id: 'fichaAvaliacaoTerceiroNonoAno',
            text: '3º ANO AO 9º ANO',
            path: urlsDocente.fichaAvaliacaoTerceiroNonoAno,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED' && moduloDocenteGrupoDiario == 1,
          },
        }
      },    
      riap: {
        id: 'riap',
        text: 'RIAP',
        type: 'title',
        visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' && moduloDocenteGrupoDiario == 1,
        // visible: false,
        itens: {
          riapCreche: {
            id: 'riapCreche',
            text: 'CRECHE | PRÉ',
            path: urlsDocente.riapCreche,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' && moduloDocenteGrupoDiario == 1,
          },
          riapInfantil: {
            id: 'riapInfantil',
            text: 'INFANTIL',
            path: urlsDocente.riapInfantil,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' && moduloDocenteGrupoDiario == 1,
          },
          riapFundamental: {
            id: 'riapFundamental',
            text: 'FUNDAMENTAL',
            path: urlsDocente.riapFundamental,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' && moduloDocenteGrupoDiario == 1,
          },
          riapEja: {
            id: 'riapInfantil',
            text: 'EJA',
            path: urlsDocente.riapEja,
            icon: 'collections_bookmark',
            visible: process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' && moduloDocenteGrupoDiario == 1,
          },
        }
      },    
})
}

export default linksDocente;