import mock from "./mock";
import api from "..";

async function getLivros(skip, limit) {
  return await api
    .get(`biblioteca?skip=${skip}&limit=${limit}`)
    .then(async (res) => {
      return res.data;
    });
}

async function getLivro(id) {
  const findLivroById = mock.find((element) => element.id === id);
  return await findLivroById;
}

async function postLivro(data) {
  return await api
    .post('biblioteca', data)
    .then(async (res) => {
      const { message } = res.data;
      return message;
    });
}

async function alocarLivro(data) {
  return await api
    .post('biblioteca/alocar', data)
    .then(async (res) => {
      return res.data;
    });
}

async function putLivro(id, data) {
  const findLivroById = mock.find((element) => element.id === id);
  const index = mock.indexOf(findLivroById);
  mock[index] = {...findLivroById,...data };
  return await mock[index];
}

async function deleteLivro(id) {
  const findLivroById = mock.find((element) => element.id === id);
  const index = mock.indexOf(findLivroById);
  mock.splice(index, 1);
  return await findLivroById;
}

export { getLivros, getLivro, postLivro, putLivro, deleteLivro, alocarLivro };
