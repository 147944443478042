import { useMemo, useEffect, useState } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import Table from '../../components/table';
import BaseGestaoUnidades from '../gestao-unidades/baseGestaoUnidades';
import BaseGestao from '../gestao/baseGestao';
import BaseDocente from '../docente/baseDocente';
import urlsGestao from '../gestao/urlsGestao';
import urlsGestaoUnidades from '../gestao-unidades/urlsGestaoUnidades';
import { useNavigate } from 'react-router-dom';
import { getRelatorioAlunos } from '../../services/relatorios';
import { getAlunosFiltro } from '../../services/alunos';
import Select from 'react-select';
import api from '../../services/index';
import { Modal, Button, Dropdown } from 'react-bootstrap';

import { getTurmasUnidadeEscolaridadeTurno } from '../../services/turmas';
import { getEscolaridades as todasEscolaridades, getEscolaridadesUnidade } from '../../services/escolaridades';
import { getTurnos as todosTurnos, getTurnosUnidadeEscolaridade } from '../../services/turnos';
import { getUnidades } from '../../services/unidades';
import { useQuery } from 'react-query';
import { store } from '../../store';
import { adicionarAvisos } from '../../services/familia-conectada';
import { toast } from 'react-toastify';
import { imprimirFichaIndividual } from '../../services/ficha-avaliacao';
import handleDownloadPDF from '../../utils/handleDownloadPDF';

const AlunosPage = (gestao) => {
  const currentUser = store.getState()['user']['user'];
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [alunoId, setAlunoId] = useState(0);

  let BaseComponent;
  let BasePath;
  let editarUrl;
  let adicionarUrl;
  let evadirUrl;
  let aprovacaoUrl;

  async function adicionarAviso() {
    const data = {
      titulo,
      descricao,
      servidorId: currentUser?.servidorId,
      alunoId: alunoId,
      unidadeId: unidadeId,
    };

    try {
      let response = await adicionarAvisos(data);
      if (response?.statusCode === 200) {
        toast.success('Aviso adicionado com sucesso');
        handleClose();
      } else {
        toast.error('Erro ao adicionar aviso');
      }
    } catch (error) {
      console.error('Erro ao adicionar aviso:', error);
      toast.error('Erro ao adicionar aviso');
    }
  }

  function handleOpenModal(alunoId) {
    setAlunoId(alunoId);
    setShow(true);
  }

  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão';
    editarUrl = urlsGestao.alunosEditar;
    aprovacaoUrl = urlsGestao.alunosAprovacao;
    adicionarUrl = urlsGestao.alunosAdicionar;
    evadirUrl = urlsGestao.alunosEvadir;
  }
  if (!gestao.gestao && !gestao.docente) {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades';
    editarUrl = urlsGestaoUnidades.alunosEditar;
    aprovacaoUrl = urlsGestaoUnidades.alunosAprovacao;
    adicionarUrl = urlsGestaoUnidades.alunosAdicionar;
    evadirUrl = urlsGestaoUnidades.alunosEvadir;
  }
  if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente';
  }

  const [search, setSearch] = useState('');
  const [pesquisarResponsavel, setPesquisarResponsavel] = useState('');
  const [pesquisarAluno, setPesquisaAluno] = useState('');
  const [pesquisaCPF, setpesquisaCPF] = useState('');
  const [data, setData] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [openPicker, setOpenPicker] = useState(false);

  const unidadeId = JSON.parse(localStorage.getItem('unidadeID'));
  const anoLetivoAtual = process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' ? 0 : 2024;

  const filtroAlunosAnoLetivo = localStorage.getItem('filtroAlunosAnoLetivo') || anoLetivoAtual;
  const filtroAlunosEscolaridade = localStorage.getItem('filtroAlunosEscolaridade') || 0;
  const filtroAlunosTurno = localStorage.getItem('filtroAlunosTurno') || 0;
  const filtroAlunosTurma = localStorage.getItem('filtroAlunosTurma') || 0;
  const filtroAlunosRenovacao = localStorage.getItem('filtroAlunosRenovacao') || 0;
  const filtroAlunosMatriculados = localStorage.getItem('filtroAlunosMatriculados') || 0;

  const [escolaridade, setEscolaridade] = useState(filtroAlunosEscolaridade);
  const [unidade, setUnidade] = useState(gestao.gestao ? 0 : unidadeId);
  const [turno, setTurno] = useState(filtroAlunosTurno);
  const [turma, setTurma] = useState(filtroAlunosTurma);
  const [matriculado, setMatriculado] = useState(filtroAlunosMatriculados);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [renovacao, setRenovacao] = useState(filtroAlunosRenovacao);
  const [anoLetivo, setAnoLetivo] = useState(filtroAlunosAnoLetivo);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const { isError, isLoading, refetch } = useQuery(
    'alunos',
    () =>
      getAlunosFiltro(
        unidade,
        escolaridade,
        turno,
        turma,
        matriculado,
        renovacao,
        pesquisarAluno,
        pesquisaCPF,
        pesquisarResponsavel,
        skip,
        limit,
        anoLetivo
      ),
    {
      retry: 0,
      enabled: false,
      onSuccess: (data) => {
        console.log(data);
        setData(data);
      },
    }
  );

  useEffect(() => {
    refetchUnidades();
  }, [unidade]);

  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery(
    'getUnidades',
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: 'TODOS' }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
        });

        setUnidades(dados);
      }
    }
  );

  async function clickImprimir(alunoId, nomeAluno){
    if (alunoId == 0){
        toast.warn('Selecione um trimestre e um aluno para impressão!')
        return
    }
    try {
      const pdf = await imprimirFichaIndividual(alunoId);
      if (pdf === undefined){
          toast.error('Não foi possível gerar o relatório.')
          return
      }
      let nomeArquivo = `FICHA_INDIVIDUAL_${nomeAluno}.pdf`; 
      handleDownloadPDF(pdf, nomeArquivo);
  } catch (error) {
      toast.error('Não foi possível gerar o relatório.');
      console.error('Erro ao gerar o relatório:', error);
  }
}

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades
  } = useQuery(
    'getEscolaridades',
    () => ((unidade !== null && unidade > 0) ? getEscolaridadesUnidade(unidade) : todasEscolaridades()),
    {
      retry: 0,
      enabled: false
    }
  );

  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos
  } = useQuery(
    'getTurnos',
    () =>
      (unidade !== null && unidade != 0) && escolaridade !== 0
        ? getTurnosUnidadeEscolaridade(unidade, escolaridade)
        : todosTurnos(),
    {
      retry: 0,
      enabled: false
    }
  );

  const {
    data: turmas,
    isLoading: loadingTurmas,
    refetch: refetchTurmas
  } = useQuery(
    'getTurmas',
    () =>
      unidade !== null && escolaridade !== null && turno !== null
        ? getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, anoLetivo)
        : null,
    {
      retry: 0,
      enabled: false
    }
  );

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Ações
      </Dropdown.Toggle>
    
      <Dropdown.Menu>
        {row.original.turmaID !== 0 && (
          <Dropdown.Item
            className="dropdown-item-danger"
            onClick={() =>
              navigate(evadirUrl, {
                state: {
                  id: row.original.id,
                  aluno: {
                    nome: row.original.nome,
                    responsavel: row.original.responsavel.nome,
                    escolaridade: row.original.escolaridade,
                    escolaridadeId: row.original.escolaridadeId,
                  },
                },
              })
            }
          >
            <i className="material-icons-two-tone">remove</i> Excluir
          </Dropdown.Item>
        )}
        <Dropdown.Item
          className="dropdown-item-primary"
          onClick={() =>
            navigate(editarUrl + row.original.id, {
              state: {
                id: row.original.pessoaID,
                aluno: {
                  nome: row.original.nome,
                  cpf: row.original.cpf,
                  responsavel: row.original.responsavel.nome,
                  status: row.original.status,
                },
              },
            })
          }
        >
          <i className="material-icons-two-tone">edit_note</i> Editar
        </Dropdown.Item>
        <Dropdown.Item
          className="dropdown-item-warning"
          onClick={() => handleOpenModal(row.original.id)}
        >
          <i className="material-icons-two-tone">add</i> Notificar
        </Dropdown.Item>
        {process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED' && (
                 <Dropdown.Item
                 className="dropdown-item-warning"
                 onClick={() => clickImprimir(row.original.id, row.original.nome)}
               >
                 <i className="material-icons-two-tone">print</i> Ficha Inidvidual
               </Dropdown.Item>
                )}
       
      </Dropdown.Menu>
    </Dropdown>
    
    );
  };

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Nascimento', accessor: 'nascimento',
        Cell: ({ row }) => (
          row.original.nascimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      { Header: 'CPF', accessor: 'cpf' },
      { Header: 'Matrícula', accessor: 'numeroMatricula' },
      { Header: 'Unidade', accessor: 'unidade' },
      { Header: 'Escolaridade', accessor: 'escolaridade' },
      {
        Header: 'PcD', accessor: 'pcd',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.pne ? 'success' : 'danger'}`}>
            {row.original.pne ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => <ActionsMenu row={row} />,
      }
    ],
    [navigate]
  );

  useEffect(() => {
    refetch();
    refetchEscolaridades();
    refetchTurnos();
    refetchTurmas();
  }, [refetch, limit, skip]);

  const handleSearch = () => {
    refetch();
  };

  const handleDownloadExcel = async () => {
    await getRelatorioAlunos(
      unidade,
      escolaridade,
      turno,
      turma,
      matriculado,
      renovacao,
      pesquisarAluno,
      pesquisaCPF,
      pesquisarResponsavel,
      skip,
      limit
    );
  };

  return (
    <BaseComponent>
      <Breadcrumb
        title={'Alunos'}
        itens={[BasePath, 'Alunos', 'Lista Geral']}
        hasExcelButton
        onClickDownload={() => handleDownloadExcel()}
        actions={{
          link: adicionarUrl,
          text: 'Novo Aluno'
        }}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Aviso | Aluno</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="form-group" style={{ width: '100%' }}>
              <label htmlFor="titulo" className="form-label">
                TÍTULO
              </label>
              <textarea
                onChange={(e) => {
                  setTitulo(e.target.value);
                }}
                value={titulo}
                className="form-control"
                id="titulo"
                rows="1"
                placeholder="REUNIÃO"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="form-group" style={{ width: '100%' }}>
              <label htmlFor="descricao" className="form-label">
                DESCRIÇÃO
              </label>
              <textarea
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
                value={descricao}
                className="form-control"
                id="descricao"
                rows="3"
                placeholder="REUNIÃO DE PAIS DIA ..."
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={adicionarAviso}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row" style={{ marginBottom: 20 }}>
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              isDisabled={gestao.gestao ? false : true}
              value={unidades.find(x => x.value == unidade)}
              placeholder=''
              onChange={async e => {
                setUnidade(e.value);
                await setEscolaridade(0);
                await setTurno(0);
                await setTurma(0);
                refetch();
                refetchEscolaridades();
              }}
            />
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="anoLetivo" className="form-label">Ano Letivo</label>
            <select
              className="form-select"
              id="anoLetivo"
              value={anoLetivo}
              onChange={async (e) => {
                await setAnoLetivo(e.target.value);
                localStorage.setItem('filtroAlunosAnoLetivo', e.target.value);
                refetch();
              }}
            >
              {process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' && 
                <option value={0}>TODOS</option>
              }
              {!gestao.docente && process.env.REACT_APP_SECRETARIA_MUNICIPAL !== 'SEMED' && 
                <option value={2023}>2023</option>
              }
              <option value={2024}>2024</option>
            </select>
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="escolaridadeID" className="form-label">
              Escolaridades
            </label>
            <select
              className="form-select"
              id="escolaridadeID"
              value={escolaridade}
              onChange={async e => {
                await setEscolaridade(e.target.value);
                await setTurno(0);
                await setTurma(0);
                await refetchTurnos(0);
                await refetchTurmas(0);
                await localStorage.setItem('filtroAlunosEscolaridade', e.target.value);
                await localStorage.setItem('filtroAlunosTurno', 0);
                await localStorage.setItem('filtroAlunosTurma', 0);
                await refetch();
              }}
            >
              {loadingEscolaridades ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {escolaridades &&
                    escolaridades.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-1">
          <div className="form-group">
            <label htmlFor="turnoID" className="form-label">
              Turnos
            </label>
            <select
              className="form-select"
              id="turnoID"
              value={turno}
              onChange={async e => {
                await setTurno(e.target.value);
                localStorage.setItem('filtroAlunosTurno', e.target.value);
                setTurma(0);
                localStorage.setItem('filtroAlunosTurma', e.target.value);
                refetchTurmas();
                refetch();
              }}
            >
              {loadingTurnos ? (
                <option value={0}></option>
              ) : (
                <>
                  <option value={0}></option>
                  {turnos &&
                    turnos.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="turmaID" className="form-label">
              Turmas
            </label>
            <select
              className="form-select"
              id="turmaID"
              value={turma}
              onChange={async e => {
                await setTurma(e.target.value);
                localStorage.setItem('filtroAlunosTurma', e.target.value);
                refetch();
              }}
            >
              {loadingTurmas ? (
                <option value=""></option>
              ) : (
                <>
                  <option value=""></option>
                  {turmas?.data &&
                    turmas?.data.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.descricao} {unidade == 0 ? ' - ' + unidades.find(unidade => unidade.value === item.unidadeId)?.label || '' : ''}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>

        <div className="col-12 col-md-1">
          <div className="form-group">
            <label htmlFor="matriculados" className="form-label">
              Matriculados?
            </label>
            <select
              className="form-select"
              id="matriculados"
              value={matriculado}
              onChange={async e => {
                await setMatriculado(e.target.value);
                localStorage.setItem('filtroAlunosMatriculados', e.target.value);
                refetch();
              }}
            >
              <>
                <option value={0}>TODOS</option>
                <option value={1}>SIM</option>
                <option value={2}>NÃO</option>
              </>
            </select>
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 20
        }}
      >
        <div className="form-group" style={{ width: '100%', maxWidth: 390, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="CPF"
            value={pesquisaCPF}
            onChange={e => setpesquisaCPF(e.target.value)}
          />
        </div>

        <div className="form-group" style={{ width: '100%', maxWidth: 395, marginRight: 10 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome do Aluno"
            value={pesquisarAluno}
            onChange={e => setPesquisaAluno(e.target.value)}
          />
        </div>

        <div className="form-group" style={{ width: '100%', maxWidth: 395 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Nome Reponsável"
            value={pesquisarResponsavel}
            onChange={e => setPesquisarResponsavel(e.target.value)}
          />
        </div>
        <div
          className="form-group"
          style={{
            marginTop: 6,
            marginLeft: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleSearch()}
          >
            <i className="material-icons-two-tone">search</i> Buscar
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data?.data ? data?.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
              hiddenColluns={['escolaridade', 'status', gestao.docente ? 'actions' : '']}
            />
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default AlunosPage;
