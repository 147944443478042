import React, { useEffect } from 'react';
import { postNotaLinhaRiapFundamental } from '../../../services/ficha-avaliacao';
import { toast } from 'react-toastify';
import { useState } from 'react';

const TabelaFundamentalRiap = React.memo(({ alunos, disciplina, ciclo, turma }) => {

    const [salvando, setSalvando] = useState(false);
    const [inputValues, setInputValues] = useState({});

    console.log(alunos)

    useEffect(() => {
        if (!alunos || alunos.length === 0) {
            return;
        }
        setInputValues(
            alunos.reduce((acc, row) => {
                acc[row.id] = {
                    aV1: row.registro === true ? row.aV1 : '',
                    aV2: row.registro === true ? row.aV2 : '',
                    aV3: row.registro === true ? row.aV3 : '',
                    recuperacao: row.registro === true ? row.recuperacao : '',
                    faltas: row.registro === true ? row.faltas : ''
                };
                return acc;
            }, {})
        );
    }, [alunos]);

    function handleChange(row, field, value) {
        setInputValues({
            ...inputValues,
            [row.id]: {
                ...inputValues[row.id],
                [field]: value
            }
        });
    }

    useEffect(() => {
        if (!alunos || alunos.length === 0) {
            return; // If alunos is falsy or empty, don't do anything
        }

        console.log("Alunos changed");

    }, [alunos]); // Run this effect whenever alunos changes

    async function handleSalvar(row) {
        // if(inputValues[row.id].ae === '' || inputValues[row.id].ad === '' || inputValues[row.id].recuperacao === '' || inputValues[row.id].faltas === ''){
        //     toast.warn('Preencha todos os campos!');
        //     return;
        // }

        handleBlur(row, { target: { value: inputValues[row.id].aV1 } }, 1);
        handleBlur(row, { target: { value: inputValues[row.id].aV2 } }, 2);
        handleBlur(row, { target: { value: inputValues[row.id].aV3 } }, 3);
        handleBlur(row, { target: { value: inputValues[row.id].recuperacao } }, 4);
        handleBlur(row, { target: { value: inputValues[row.id].faltas } }, 5);

        // if (inputValues[row.id].ae > 7 || inputValues[row.id].ad > 3 || inputValues[row.id].recuperacao > 7) {
        //     return;
        // }

        setSalvando(true);
        try {
            let data = {
                alunoId: row.id,
                disciplinaId: disciplina,
                trimestreId: ciclo,
                turmaId: turma,
                aV1: inputValues[row.id].aV1,
                aV2: inputValues[row.id].aV2,
                aV3: inputValues[row.id].aV3,
                recuperacao: inputValues[row.id].recuperacao === '' ? -1 : inputValues[row.id].recuperacao,
                faltas: inputValues[row.id].faltas
            }
    
            const response = await postNotaLinhaRiapFundamental(data);
    
            if (response?.statusCode === undefined) {
                return
            }
            if (response.statusCode === 200) {
                toast.success('Lançamento efetuado com sucesso!');
            }
            else {
                toast.error('Erro ao efetuar lançamento!');
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao salvar notas!');
        } finally {
            setSalvando(false);
        }
    }

    async function handleBlur(row, e, tipoLancamento) {

        // if (tipoLancamento === 1 && e.target.value > 7) {
        //     toast.warn('O valor máximo para AE é 7!');
        //     return;
        // }

        // if (tipoLancamento === 2 && e.target.value > 3) {
        //     toast.warn('O valor máximo para AD é 3!');
        //     return;
        // }

        // if (tipoLancamento === 3 && e.target.value > 7 && e.target.value !== '') {
        //     toast.warn('O valor máximo para recuperação é 7!');
        //     return;
        // }

        
    }

    if (!alunos || alunos.length === 0) {
        return null; // If alunos is falsy or empty, don't render anything
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover w-100">
                <thead>
                    <tr className="text-center">
                        <th style={{ display: 'none' }} className="text-center">ID</th>
                        <th className="text-center">MATRÍCULA</th>
                        <th className="text-center">NOME</th>
                        <th className="text-center">AV1</th>
                        <th className="text-center">AV2</th>
                        <th className="text-center">AV3</th>
                        <th className="text-center">RECUPERAÇÃO</th>
                        <th className="text-center">FALTAS</th>
                        <th className="text-center">AÇÕES</th>
                    </tr>
                </thead>
                <tbody>
                    {alunos.map((row) => (
                        console.log(row),
                        <tr key={row.id} className="text-center">
                            <td style={{ display: 'none' }}>{row.id}</td>
                            <td>{row.matricula}</td>
                            <td>{row.nome}</td>
                            <td>
                                <input
                                    className="form-control"
                                    type="number"
                                    aria-labelledby="aria-label"
                                    id={'aV1' + row.id}
                                    name={'aV1' + row.id}
                                    placeholder=""
                                    defaultValue={row.registro === true ? row.aV1 : null}
                                    onChange={(e) => handleChange(row, 'aV1', e.target.value)}
                                    onBlur={(e) => { handleBlur(row, e, 1) }}
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="number"
                                    aria-labelledby="aria-label"
                                    id={'aV2' + row.id}
                                    name={'aV2' + row.id}
                                    placeholder=""
                                    defaultValue={row.registro === true ? row.aV2 : null}
                                    onChange={(e) => handleChange(row, 'aV2', e.target.value)}
                                    onBlur={(e) => { handleBlur(row, e, 2) }}
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="number"
                                    aria-labelledby="aria-label"
                                    id={'aV3' + row.id}
                                    name={'aV3' + row.id}
                                    placeholder=""
                                    defaultValue={row.registro === true ? row.aV3 : null}
                                    onChange={(e) => handleChange(row, 'aV3', e.target.value)}
                                    onBlur={(e) => { handleBlur(row, e, 3) }}
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="number"
                                    aria-labelledby="aria-label"
                                    id={'recuperacao' + row.id}
                                    name={'recuperacao' + row.id}
                                    placeholder=""
                                    defaultValue={row.registro === true && row.recuperacao !== -1 ? row.recuperacao : null}
                                    onBlur={(e) => { handleBlur(row, e, 3) }}
                                    onChange={(e) => handleChange(row, 'recuperacao', e.target.value)}
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="number"
                                    aria-labelledby="aria-label"
                                    id={'faltas' + row.id}
                                    name={'faltas' + row.id}
                                    placeholder=""
                                    defaultValue={row.registro === true ? row.faltas : null}
                                    onBlur={(e) => { handleBlur(row, e, 4) }}
                                    onChange={(e) => handleChange(row, 'faltas', e.target.value)}
                                    onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                />
                            </td>
                            <button style={{marginTop: 15}} type="button" class="btn" onClick={() => handleSalvar(row)}  disabled={salvando}>Salvar</button>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
});

export default TabelaFundamentalRiap;
