import RiapInfantil from "../riap/infantil";

const baseUrl = '/docente';

const urlsDocente = {
    dashboard: baseUrl,
    calendario: `${baseUrl}/calendario`,
    alunos: `${baseUrl}/alunos`,
    faltas: `${baseUrl}/faltas`,
    noticias: `${baseUrl}/noticias`,
    faltasAdicionar: `${baseUrl}/faltas/adicionar/`,
    faltasAdicionarId: `${baseUrl}/faltas/adicionar/:id`,
    notas: `${baseUrl}/notas`,
    notasAdicionar: `${baseUrl}/notas/adicionar/`,
    notasAdicionarId: `${baseUrl}/notas/adicionar/:id`,
    notasAvaliacoes: `${baseUrl}/notas/avaliacoes/`,
    notasAvaliacoesId: `${baseUrl}/notas/avaliacoes/:id`,
    notasAvaliacoesAlunos: `${baseUrl}/notas/avaliacoes/alunos/`,
    notasAvaliacoesAlunosId: `${baseUrl}/notas/avaliacoes/alunos/:id`,
    notasVisualizacao: `${baseUrl}/notas/visualizacao`,
    // diarioClasse: `${baseUrl}/diario-classe`,
    turmas: `${baseUrl}/turmas`,
    turmasAlunos: `${baseUrl}/turmas/alunos/`,
    turmasAlunosId: `${baseUrl}/turmas/alunos/:id`,
    quadroHorario: `${baseUrl}/quadro-horario`,
    quadroHorarioImpressao: `${baseUrl}/quadro-horario/imprimir`,
    bnncFundamental: `${baseUrl}/bncc-fundamental`,
    fichaAvaliacao: `${baseUrl}/ficha-avaliacao`,
    fichaAvaliacaoVisualizacao: `${baseUrl}/ficha-avaliacao`,
    fichaAvaliacaoLancamento: `${baseUrl}/ficha-avaliacao/lancamento`,
    fichaAvaliacaoImpressao: `${baseUrl}/ficha-avaliacao/impressao`,
    fichaAvaliacaoInfantil: `${baseUrl}/ficha-avaliacao/infantil`,
    fichaAvaliacaoInfantilImpressao: `${baseUrl}/ficha-avaliacao/infantil/impressao`,
    fichaAvaliacaoPrimeiroAno: `${baseUrl}/ficha-avaliacao/primeiro-ano`,
    fichaAvaliacaoPrimeiroAnoImpressao: `${baseUrl}/ficha-avaliacao/primeiro-ano/impressao`,
    fichaAvaliacaoSegundoAno: `${baseUrl}/ficha-avaliacao/segundo-ano`,
    fichaAvaliacaoSegundoAnoImpressao: `${baseUrl}/ficha-avaliacao/segundo-ano/impressao`,
    fichaAvaliacaoTerceiroNonoAno: `${baseUrl}/ficha-avaliacao/terceiro-nono-ano`,
    fichaAvaliacaoTerceiroNonoAnoImpressao: `${baseUrl}/ficha-avaliacao/terceiro-nono-ano/impressao`,
    quadroHoraio: `${baseUrl}/quadro-horario`,
    quadroHorarioImpressao: `${baseUrl}/quadro-horario/imprimir`,
    riapCreche: `${baseUrl}/riap/creche-pre`,
    riapInfantil: `${baseUrl}/riap/infantil`,
    riapFundamental: `${baseUrl}/riap/fundamental`,
    riapEja: `${baseUrl}/riap/eja`,
}

export default urlsDocente;