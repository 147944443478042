const baseUrl = '/pre-matricula';

const urlsPreMatricula = {
    dashboard: baseUrl,
    inscricoes: `${baseUrl}/inscricoes`,
    statusId: `${baseUrl}/status/:id`,
    status: `${baseUrl}/status`,
    pesquisa:   `${baseUrl}/pesquisa`,

    relatorioInscritosPorBairro : `${baseUrl}/relatorios/inscritos/porBairro`,
    relatorioInscritosPorPne : `${baseUrl}/relatorios/inscritos/porPne`,
    relatorioInscritosPorEscolaridade : `${baseUrl}/relatorios/inscritos/porEscolaridade`,
    relatorioInscritosPorNovoRede : `${baseUrl}/relatorios/inscritos/porNovoRede`,
    relatorioInscritosPorUnidade : `${baseUrl}/relatorios/inscritos/porUnidade`,
    relatorioInscritosPorUnidadeNominal : `${baseUrl}/relatorios/inscritos/porUnidadeNominal`,
    relatorioInscritosPorTransExterna : `${baseUrl}/relatorios/inscritos/porTransExterna`,
    relatorioInscritosPorTransInterna : `${baseUrl}/relatorios/inscritos/porTransInterna`,
    relatorioInscritosPorGeral : `${baseUrl}/relatorios/inscritos/porGeral`,
    relatorioInscritosPorPeriodoQuantitativo : `${baseUrl}/relatorios/inscritos/porPeriodoQuantitativo`,

    relatorioConvocadosPorEscolaridade : `${baseUrl}/relatorios/convocados/porEscolaridade`,
    relatorioConvocadosPorPeriodoNominal : `${baseUrl}/relatorios/convocados/porPeriodoNominal`,
    relatorioConvocadosPorPeriodoQuantitativo : `${baseUrl}/relatorios/convocados/porPeriodoQuantitativo`,
    relatorioMatriculadosPorEscolaridade : `${baseUrl}/relatorios/matriculados/porEscolaridade`,
    relatorioMatriculadosPorGeral : `${baseUrl}/relatorios/matriculados/porGeral`,
    relatorioMatriculadosPorUnidade : `${baseUrl}/relatorios/matriculados/porUnidade`,
    relatorioMatriculadosPorPeriodoNominal : `${baseUrl}/relatorios/matriculados/porPeriodoNominal`,
    relatorioMatriculadosPorPeriodoQuantitativo : `${baseUrl}/relatorios/matriculados/porPeriodoQuantitativo`,
    visualizar: 'https://web.educacaoararuama.rj.gov.br/formulario/cadastro-reserva/inscricao/',
}

export default urlsPreMatricula;