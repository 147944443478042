import React, { useState } from 'react';
import BaseGestaoUnidades from '../gestao-unidades/baseGestaoUnidades';
import BaseGestao from '../gestao/baseGestao';
import BaseDocente from '../docente/baseDocente';
import Breadcrumb from '../../components/breadcrumb';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Calendario = (gestao) => {
  let BaseComponent;
  let BasePath;
  let imageUrl;
  let pdfUrl;
  let pages;
  

  if (process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC') {
    pdfUrl = process.env.PUBLIC_URL + '/assets/files/prefeituras/CALENDARIO_ARARUAMA.PDF';
    imageUrl = process.env.PUBLIC_URL + '/assets/images/prefeituras/CALENDARIO_ARARUAMA';
    pages = [1,2,3]
  } else if (process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED') {
    pdfUrl = process.env.PUBLIC_URL + '/assets/files/prefeituras/CALENDARIO_SAO_PEDRO.PDF';
    imageUrl = process.env.PUBLIC_URL + '/assets/images/prefeituras/CALENDARIO_SAO_PEDRO';
    pages = [1,2,3,4]
  }

  if (gestao.gestao) {
    BaseComponent = BaseGestao;
    BasePath = 'Gestão';
  } else if (gestao.docente) {
    BaseComponent = BaseDocente;
    BasePath = 'Docente';
  } else {
    BaseComponent = BaseGestaoUnidades;
    BasePath = 'Gestão Unidades';
  }

  const handleDownload = () => {
    window.open(pdfUrl);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 3,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const carouselImageStyle = {
    maxWidth: '100%',
    maxHeight: '70vh', // Adjust this value based on your layout needs
    margin: 'auto',
    display: 'block'
  };



  return (
    <BaseComponent>
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Breadcrumb title={'Calendário Escolar'} itens={[BasePath, 'Calendário Letivo']} />
            <button className="btn btn-success" onClick={handleDownload}>Download PDF</button>
          </div>
          <div className="card">
            <Slider {...settings}>
              {pages.map(pageNumber => (
                <div key={pageNumber}>
                  <img src={`${imageUrl}_${pageNumber}.jpg`} alt={`Calendario ${pageNumber}`} style={carouselImageStyle}/>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </BaseComponent>
  );
};

export default Calendario;
