import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeDocente from '../../pages/docente/homeDocente';
import urlsDocente from '../../pages/docente/urlsDocente';
import Calendario from '../../pages/calendarios';
import AlunosPage from '../../pages/alunos';
import FaltasPage from '../../pages/faltas';
import FaltasAdicionarPage from '../../pages/faltas/cadastrar';
import CalendarioPage from '../../pages/calendarios';
import DiarioClassePage from '../../pages/diario-classe';
import DiarioClasseAdicionarPage from '../../pages/diario-classe/cadastrar';
import DiarioClasseAvaliacoesPage from '../../pages/diario-classe/avaliacoes';
import DiarioClasseAvaliacoesAlunosPage from '../../pages/diario-classe/avaliacoes/alunos';
import VisualizacaoDiarioPage from '../../pages/diario-classe/visualizacao';
import BanccFundamentalPage from '../../pages/bncc/fundamental';
// import FichaAvaliacaoTable from '../../pages/ficha-avaliacao/visualizacao';
import VisualizacaoFichaAvaliacaoPage from '../../pages/ficha-avaliacao/visualizacao';
import HomeFichaAvaliacao from '../../pages/ficha-avaliacao/homeFichaAvaliacao';
import ImpressaoFichaAvaliacao from '../../pages/ficha-avaliacao/impressao';
import FichaAvaliacaoVisualizacaoInfantil from '../../pages/ficha-avaliacao/infantil';
import FichaAvaliacaoVisualizacaoPrimeiroAno from '../../pages/ficha-avaliacao/primeiro-ano';
import FichaAvaliacaoVisualizacaoSegundoAno from '../../pages/ficha-avaliacao/segundo-ano';
import FichaAvaliacaoVisualizacaoTerceiroNonoAno from '../../pages/ficha-avaliacao/terceiro-nono-ano';
import FichaAvaliacaoImpressaoInfantil from '../../pages/ficha-avaliacao/infantil/impressao';
import FichaAvaliacaoImpressao1Ano from '../../pages/ficha-avaliacao/primeiro-ano/impressao';
import FichaAvaliacaoImpressaoSegundoAno from '../../pages/ficha-avaliacao/segundo-ano/impressao';
import FichaAvaliacaoImpressaoTerceiroAoNonoAno from '../../pages/ficha-avaliacao/terceiro-nono-ano/impressao';
import QuadroDeHorarioDocente from '../../pages/quadro-horarios/disciplinas/docente';
import QuadroHorarioImpressaoDocente from '../../pages/quadro-horarios/imprimir/docente';
import RiapCreche from '../../pages/riap/creche';
import RiapInfantil from '../../pages/riap/infantil';
import RiapFundamental from '../../pages/riap/fundamental';
import RiapEja from '../../pages/riap/eja';

const defaultPermisson = false;

const enableSemed = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEMED' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';
const enableAraruama = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';

const DocenteRoutes = () => (
    <React.Fragment>
        <Route path={urlsDocente.dashboard} element={<HomeDocente />} /> 
        <Route path={urlsDocente.bnncFundamental} element={<BanccFundamentalPage gestao={false} docente={true} configuracao={false} />} /> 
        <Route path={urlsDocente.calendario} element={<Calendario gestao={false} docente={true} />} /> 
        <Route path={urlsDocente.alunos} element={<AlunosPage gestao={false} docente={true} />} /> 
        <Route path={urlsDocente.faltas} element={<FaltasPage gestao={false} docente={true} />} /> 
        <Route path={urlsDocente.faltasAdicionar} element={<FaltasAdicionarPage gestao={false} docente={true} />} />
        <Route path={urlsDocente.calendario} element={<CalendarioPage gestao={false} docente={true} />} />
        <Route path={urlsDocente.quadroHoraio} element={<QuadroDeHorarioDocente gestao={false} docente={true} />} />
        <Route path={urlsDocente.quadroHorarioImpressao} element={<QuadroHorarioImpressaoDocente gestao={false} docente={true} />} />


        <Route exact path={urlsDocente.notas} element={ <DiarioClassePage gestao={false} docente={true} />}/>
        <Route exact path={urlsDocente.notasAdicionar} element={ <DiarioClasseAdicionarPage gestao={false} docente={true} />}/>
        <Route exact path={urlsDocente.notasAdicionarId} element={ <DiarioClasseAdicionarPage gestao={false} docente={true} />}/>
        <Route exact path={urlsDocente.notasAvaliacoes} element={ <DiarioClasseAvaliacoesPage gestao={false} docente={true} />}/>
        <Route exact path={urlsDocente.notasAvaliacoesId} element={ <DiarioClasseAvaliacoesPage gestao={false} docente={true} />}/>
        <Route exact path={urlsDocente.notasAvaliacoesAlunos} element={ <DiarioClasseAvaliacoesAlunosPage gestao={false} docente={true} />}/>
        <Route exact path={urlsDocente.notasAvaliacoesAlunosId} element={ <DiarioClasseAvaliacoesAlunosPage gestao={false} docente={true} />}/>
        <Route exact path={urlsDocente.notasVisualizacao} element={ <VisualizacaoDiarioPage gestao={false} docente={true} />}/>  
       
            {enableSemed && (
        <>
          <Route exact path={urlsDocente.fichaAvaliacao} element={ <VisualizacaoFichaAvaliacaoPage gestao={false} docente={true} />}/>  
            <Route exact path={urlsDocente.fichaAvaliacaoLancamento} element={ <HomeFichaAvaliacao gestao={false} docente={true} />}/>  
            <Route exact path={urlsDocente.fichaAvaliacaoImpressao} element={ <ImpressaoFichaAvaliacao gestao={false} docente={true} />}/>  
            <Route exact path={urlsDocente.fichaAvaliacaoInfantil} element={ <FichaAvaliacaoVisualizacaoInfantil gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.fichaAvaliacaoPrimeiroAno} element={ <FichaAvaliacaoVisualizacaoPrimeiroAno gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.fichaAvaliacaoSegundoAno} element={ <FichaAvaliacaoVisualizacaoSegundoAno gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.fichaAvaliacaoTerceiroNonoAno} element={ <FichaAvaliacaoVisualizacaoTerceiroNonoAno gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.fichaAvaliacaoInfantilImpressao} element={ <FichaAvaliacaoImpressaoInfantil gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.fichaAvaliacaoPrimeiroAnoImpressao} element={ <FichaAvaliacaoImpressao1Ano gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.fichaAvaliacaoSegundoAnoImpressao} element={ <FichaAvaliacaoImpressaoSegundoAno gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.fichaAvaliacaoTerceiroNonoAnoImpressao} element={ <FichaAvaliacaoImpressaoTerceiroAoNonoAno gestao={false} docente={true} />}/>
        </>
        )}

{enableAraruama && (
        <>
         <Route exact path={urlsDocente.riapCreche} element={ <RiapCreche gestao={false} docente={true} />}/>
         <Route exact path={urlsDocente.riapPre} element={ <RiapCreche gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.riapInfantil} element={ <RiapInfantil gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.riapFundamental} element={ <RiapFundamental gestao={false} docente={true} />}/>
            <Route exact path={urlsDocente.riapEja} element={ <RiapEja gestao={false} docente={true} />}/>
          </>
        )}
      
    </React.Fragment>
);

export default DocenteRoutes;
