import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import BaseGestao from "../../gestao/baseGestao";
import Breadcrumb from "../../../components/breadcrumb";
import { useForm } from 'react-hook-form'
import FormInput from "../../../components/formInput";
import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react'
import { LocationApi } from "../../../services/external/cep";
import { toast } from 'react-toastify'
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { getEscolaridadesUnidade } from "../../../services/escolaridades";
import { getTurnosUnidadeEscolaridade } from "../../../services/turnos";
import { getTurmasUnidadeEscolaridadeTurno } from "../../../services/turmas";

import { getAluno, logsAluno, createAluno, updateAluno, putUpdateAluno, getAlunosFiltro } from "../../../services/alunos";
import { useQuery } from 'react-query';
import Table from '../../../components/table'
import removeEmpty from "../../../utils/removeEmpty";
import upperCaseSimple from "../../../utils/upperCaseSimple";
import Spinner from '../../../components/spinner'
import api from "../../../services";
import { validate as isCPFValid } from 'cpf-check';


const AlunosAdicionarPage = (gestao) => {

  const [pne, setPne] = useState(null);

  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate()
  const [cpfEdicao, setCpfEdicao] = useState('');

  const validacaoCpf = id ? false : true;

  const [unidade, setUnidade] = useState(localStorage.getItem('unidadeID'))
  const [escolaridade, setEscolaridade] = useState(0)
  const [turno, setTurno] = useState(0)
  const [turma, setTurma] = useState(0)
  const [historicoData, setHistoricoData] = useState([])
  
  const [loadingEscolaridades, setLoadingEscolaridades] = useState(true);
  const [loadingTurnos, setLoadingTurnos] = useState(true);
  const [loadingTurmas, setLoadingTurmas] = useState(true);

  const [dataEscolaridade, setDataEscolaridade] = useState([]);
  const [dataTurno, setDataTurno] = useState([]);
  const [dataTurma, setDataTurma] = useState([]);

  const [enableAluno, setEnableAluno] = useState(id ? true : false)
  const [enableAlunoLog, setEnableAlunoLog] = useState(true)
  const [ocultaBotaEnviar, setOcultaBotaoEnviar] = useState(false)


  useEffect(() => {
    if (process.env.REACT_APP_SECRETARIA_CIDADE === 'ARARUAMA') {
      import('../pickersAraruama').then((mod) => {
        setPne(mod.pne); // Assuming pne is what you want to use
      });
    } else {
      import('../pickersSaoPedro').then((mod) => {
        setPne(mod.pne); // Adjust according to the actual export
      });
    }
  }, []);



  const { data, loadingAluno } = useQuery('getAluno', () => id !== undefined && getAluno(id), {
    retry: false,
    enabled: enableAluno,
    onSuccess: async (data) => {
      setValue('nome', data?.nome);
      setValue('cpf', data?.cpf);
      setCpfEdicao(data?.cpf);
      setValue('rg', data?.rg);
      setValue('numeroCarteiraNascimento', formatDate(data?.numeroCarteiraNascimento));
      setValue('numeroCarteiraSus', data?.numeroCarteiraSus);
      setValue('deficiente', data?.pcD_PNE == 1 ? 1 : 0)
      setValue('filiacao1', data?.filiacao1)
      setValue('filiacao2', data?.filiacao2)
      setValue('escolaridadeID', data?.escolaridadeID)
      setValue('turnoID', data?.turnoID)
      setValue('turmaID', data?.turmaID)
      setValue('numeroMatricula', data?.numeroMatricula)
      setValue('ultimaAtualizacao', formatDate(data?.ultimaAtualizacao))
      setValue('criacao',formatDate(data?.criacao))

      // ENDEREÇO
      setValue('cep', data?.endereco.cep)
      setValue('logradouro', data?.endereco.logradouro)
      setValue('numero', data?.endereco.numero)
      setValue('complemento', data?.endereco.complemento)
      setValue('bairro', data?.endereco.bairro)
      setValue('estado', data?.endereco.estado)
      setValue('cidade', data?.endereco.cidade)
      setValue('utilizaTransporteEscolar', data?.endereco.utilizaTransporte == true ? 1 : 0)

      // RESPONSÁVEL
      setValue('responsavelCpf', data?.responsavel.cpf)
      setValue('responsavelNascimento', formatDate(data?.responsavel.nascimento))
      setValue('responsavelNome', data?.responsavel.nome)
      setValue('responsavelTelefone', data?.responsavel.telefone)

       // SOCIAS
       setValue('beneficiarioProgramaSocial', data?.social.beneficiarioProgramaSocial)
       setValue('nis', data?.social.nis)
       setValue('identificacaoUnica', data?.social.indentificacaoUnica)

      setUnidade(data?.unidadeID);
      setValue('unidadeID', data?.unidadeID)
      setEscolaridade(data?.escolaridadeID);
      setTurno(data?.turnoID);
      setTurma(data?.turmaID);

      console.log(turma)

      fetchSelect(data?.unidadeID, data?.escolaridadeID, data?.turnoID)
      
      setEnableAluno(false)
    
    }
  })


  useQuery('logsAluno', () => id !== undefined && logsAluno(id), {
    enabled: enableAlunoLog,
    retry: true,
    onSuccess: (data) => {
      setHistoricoData(data)
      setEnableAlunoLog(false)
    }
  })

  function formatDate(inputDate) {
    if (!inputDate) {
      return ''; // Return an empty string for null or undefined input
    }

    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  function zipcodeMask(value) {
    if (!value) return '';
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{5})(\d{3})$/, '$1-$2');
    return value;
  }

  const { current: locationApi } = useRef(new LocationApi());
  const { current: controller } = useRef(new AbortController());

  const { register, handleSubmit, getValues, watch, setValue, formState: { isValid, errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: "",
      nome: "",
      cpf: "",
      rg: "",
      numeroCarteiraNascimento: "",
      numeroCarteiraSus: "",
      deficiente: 0,
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      estado: "",
      utilizaTransporteEscolar: 0,
      responsavelNome: "",
      responsavelCpf: "",
      responsavelNascimento: "",
      responsavelTelefone: "",
      filiacao1: "",
      filiacao2: "",
      unidadeID: "",
      escolaridadeID: "",
      turnoID: 0,
      turmaID: 0,
      unidadeID: unidade,
      identificacaoUnica: "",
      beneficiarioProgramaSocial: 0,
      nis: "",
    }
  })
  
  const anoLetivo = state && state.aluno && state.aluno.status === 1 ? 2023 : 2024;


  async function fetchSelect(unidade = 0, escolaridade = 0, turno = 0){

    if (id === undefined){
      await fetchEscolaridade(unidade);
    }

    else{
      await fetchEscolaridade(unidade);
      if (escolaridade > 0)
        await fetchTurno(unidade, escolaridade);

      if (escolaridade > 0 && turno > 0)
        await fetchTurma(unidade, escolaridade, turno);
    }
    

  }

  async function fetchEscolaridade(unidade){
    if(unidade != 0){
      var response = await getEscolaridadesUnidade(unidade)
      setLoadingEscolaridades(false)
      setDataEscolaridade(response || [])
    }
  }

  async function fetchTurno(unidade, escolaridade){
    if(unidade != 0 && escolaridade != 0){
      var response = await getTurnosUnidadeEscolaridade(unidade, escolaridade)
      setLoadingTurnos(false)
      setDataTurno(response || [])
    }
  }

  async function fetchTurma(unidade, escolaridade, turno){
    if(unidade != 0 && escolaridade != 0 && turno != 0){
      var response = await getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, turno, anoLetivo)
      setLoadingTurmas(false)
      setDataTurma(response?.data || [])
    }
  }


  useEffect(() => {
    if (id === undefined){
      fetchSelect(unidade)
      setLoadingTurnos(false)
      setLoadingTurmas(false)
    }
   
  }, []);

  const onSubmit = async (values) => {

    const convertedValues = { ...values };
    Object.keys(convertedValues).forEach((key) => {
      if (typeof convertedValues[key] === 'string' && (convertedValues[key].toLowerCase() === 'true' || convertedValues[key].toLowerCase() === 'false')) {
        convertedValues[key] = convertedValues[key].toLowerCase() === 'true';
      }
    });

    if (parseInt(values.turnoID) === 0){
      return toast.error('O campo turno é obrigatório.');
    }

    if (parseInt(values.escolaridadeID) === 0){
      return toast.error('O campo escolaridade é obrigatório.');
    }

    if (parseInt(values.turmaID) === 0){
      return toast.error('O campo turma é obrigatório.');
    }

    if (id) {
      try {
        await putUpdateAluno({ id: id, data: removeEmpty(upperCaseSimple(values)) });

        toast.success('Operação realizada com sucesso.')
        navigate(-1)

      } catch (error) {
        // Handle errors if needed
        toast.error('Ocorreu um erro, tente novamente mais tarde.')
      }
    }
    else {
      try {
        await createAluno(values);

        toast.success('Operação realizada com sucesso.')
        navigate(-1)

      } catch (error) {
        toast.error('Ocorreu um erro, tente novamente mais tarde.')
      }
    }

  };

  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? 'Gestão' : 'Gestão Unidades';

  useEffect(() => {
    const cep = zipcodeMask(watch('cep')).replace(/\D/, '')
    const cepData = zipcodeMask(data?.endereco?.cep).replace(/\D/, '')
    if(cep != cepData){
      handlAutoCompleteForm(cep)
    }
  
  }, [watch('cep')])

  useEffect(() => {
    const checkCpf = async () => {
      setOcultaBotaoEnviar(false);
      const cpf = watch('cpf'); // Assuming 'watch' is correctly defined in your component's context

      if (cpf.length === 14 && isCPFValid(cpf) && cpf.replace(/\D/g, '') !== cpfEdicao.replace(/\D/g, '')) {
        try {
          const response = await getAlunosFiltro(0, 0, 0, 0, 0, 0,  "", cpf.replace(/\D/g, ''), "", 0, 1, 0);
          const id = response?.data;

          if (id.length > 0) {
            toast.error('CPF já cadatrado anteriormente.'); // Assuming 'toast' is correctly defined and imported
            setOcultaBotaoEnviar(true); // Assuming 'setExibeAvisoCpf' is correctly defined
          }
        } catch (error) {
          // Handle any errors here, such as network issues or API failures
          // console.error('Error fetching data: ', error);
        }
      }
    };

    checkCpf();
  }, [watch, watch('cpf')]);

  const handlAutoCompleteForm = useCallback(

    async (cep) => {
      if (cep.length < 8) return;
      try {
        const data = await locationApi.searchByCep(cep, controller.signal);
        let cidade = data?.data.data.cidade;
        let uf = data?.data.data.uf;
        let bairro = data?.data.data.bairro;
        let logradouro = data?.data.data.logradouro;


        if (data.status === 400) {
          toast.warn(data?.data.message);
        }

        setValue('bairro', bairro, {
          // shouldValidate: true,
        });
        setValue('cidade', cidade, {
          // shouldValidate: true,
        });
        setValue('estado', uf, {
          // shouldValidate: true,
        });
        setValue('logradouro', logradouro, {
          // shouldValidate: true,
        });
      } catch (error) {
        // toast.error('Não foi possível carregar o endereço, cep não encontrado na base de dados do correio.');
      }
    }
  );

  const columnsUnidades = useMemo(
    () => [
      {
        Header: 'Data Evento', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      { Header: 'Título', accessor: 'titulo' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Usuário', accessor: 'usuarioLogin' },

    ],
    [navigate]
  );


  return (
    <BaseComponent>
      <Breadcrumb title={id ? 'EDIÇÃO DE ALUNO' : 'CADASTRO DE ALUNO'} itens={[BasePath, 'Alunos', id ? 'Edição' : 'Cadastro']} />
      
      {
        loadingAluno ? (
          <Spinner />
        ) : (
          <>
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                  <ul className="nav nav-tabs" id="servidoresTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="dados-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#dados"
                        type="button"
                        role="tab"
                        aria-controls="dados"
                        aria-selected="true"
                      >
                        DADOS GERAIS
                      </button>
                    </li>
                    <li className="nav-item" role="presentation" style={{ display: id ? 'block' : 'none' }}>
                      <button
                        className="nav-link"
                        id="historico-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#historico"
                        type="button"
                        role="tab"
                        aria-controls="historico"
                        aria-selected="true"
                      >
                        HISTÓRICO
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="dados"
                      role="tabpanel"
                      aria-labelledby="dados-tab"
                    >
                      {id && (
                        <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="numeroMatricula"
                            label="Matrícula"
                            readOnly={true}
                            required={false}
                          />
                        </div>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="criacao"
                            type="date"
                            label="Data Criação"
                            readOnly={true}
                            required={false}
                          />
                        </div>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            type="date"
                            atribute="ultimaAtualizacao"
                            label="Última Atualização"
                            readOnly={true}
                            required={false}
                          />
                        </div>
                        </div>
                      )}
                      

                      <h4 style={{ marginTop: '20px', marginBottom: '1px' }}>DADOS PESSOAIS</h4>
                      <hr
                        style={{
                          background: '#000',
                          color: '#000',
                          borderColor: '#00',
                          height: '1px',
                        }}
                      />
                      <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="nome"
                            label="Nome"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="cpf"
                            mask="999.999.999-99"
                            label="CPF"
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="rg"
                            label="RG"
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: '20px' }}>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="numeroCarteiraNascimento"
                            label="Data Nascimento"
                            type="date"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="numeroCarteiraSus"
                            label="Carteira SUS"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">


                          <div className="form-group">
                            <label>PcD?<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                            <select className="form-select" id="deficiente" {...register("deficiente", { required: false })}>
                              {pne?.map((entry) => (
                                <option key={entry.id} value={entry.id}>
                                  {entry.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>




                      </div>

                      <div className="row" style={{ marginTop: '20px' }}>





                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="filiacao1"
                            label="Filiação 1"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="filiacao2"
                            label="Filiação 2"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">


                          <div className="form-group">
                            <label htmlFor="escolaridadeID">Escolaridades</label>
                            <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                            <select
                              className="form-select"
                              id="escolaridadeID"
                              required={true}
                              value={escolaridade}
                              onChange={async (e) => {
                                setEscolaridade(e.target.value);
                                setTurno(0);
                                setTurma(0)
                                setValue('escolaridadeID', e.target.value);
                                setValue('turnoID', 0);
                                setValue('turmaID', 0);
                                setLoadingTurnos(true)
                                await fetchTurno(unidade, e.target.value);
                                setDataTurma([])
                              }}
                            >
                              {
                                <>
                                  {loadingEscolaridades ? (
                                  <option value={0}>Carregando...</option>
                                ) :
                                      <option value={0}></option>
                                    }
                                  {dataEscolaridade && dataEscolaridade.map((item) => (
                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                  ))}
                                </>
                              }
                            </select>
                            {errors.escolaridadeID && <span className='text-danger'>Campo obrigatório</span>}
                          </div>
                        </div>



                      </div>

                      <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="turnoID" className="form-label">Turnos</label>
                            <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                            <select
                              className="form-select"
                              id="turnoID"
                              required={true}
                              value={turno}
                              onChange={async (e) => {
                                setTurno(e.target.value);
                                setTurma(0);
                                setValue('turnoID', e.target.value);
                                setValue('turmaID', 0);
                                setLoadingTurmas(true)
                                await fetchTurma(unidade, escolaridade, e.target.value);
                              }}>
                              {
                                <>
                                  {loadingTurnos ? (
                                  <option value={0}>Carregando...</option>
                                ) :
                                      <option value={0}></option>
                                    }
                                  {dataTurno && dataTurno.map((item) => (
                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                  ))}
                                </>
                              }
                            </select>
                            {errors.turnoID && <span className='text-danger'>Campo obrigatório</span>}
                          </div>
                        </div>

                        <div className="col-md-4">


                          <div className="form-group">
                            <label htmlFor="turmaID" className="form-label">Turmas {anoLetivo}</label>
                            
                            <select
                              className="form-select"
                              id="turmaID"
                              required={true}
                              value={turma}
                              onChange={async (e) => {
                                setTurma(e.target.value);
                                await setValue('turmaID', e.target.value);
                              }}>
                              {
                                <>
                                {loadingTurmas ? (
                                  <option value={0}>Carregando...</option>
                                ) :
                                      <option value={0}></option>
                                    }
                                  {dataTurma && dataTurma?.map((item) => (
                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                  ))}
                                </>
                              }
                            </select>

                            
                          </div>
                        </div>




                      </div>

                      <h4 style={{ marginTop: '20px', marginBottom: '1px' }}>ENDEREÇO</h4>
                      <hr
                        style={{
                          background: '#000',
                          color: '#000',
                          borderColor: '#00',
                          height: '1px',
                        }}
                      />

                      <div className="row" style={{ marginTop: '20px' }}>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="cep"
                            label="CEP"
                            mask="99999-999"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="logradouro"
                            label="Logradouro"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="numero"
                            label="Número"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: '20px' }}>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="bairro"
                            label="Bairro"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="complemento"
                            label="Complemento"
                            required={false}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="estado"
                            label="Estado"
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="cidade"
                            label="Cidade"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">


<div className="form-group">
  <label>Utiliza Transporte Escolar?</label>
  <select className="form-select" id="utilizaTransporteEscolar" {...register("utilizaTransporteEscolar", { required: false })}>
    {pne?.map((entry) => (
      <option key={entry.id} value={entry.id}>
        {entry.value}
      </option>
    ))}
  </select>
</div>
</div>


                      </div>

                      <h4 style={{ marginTop: '20px', marginBottom: '1px' }}>DADOS RESPONSÁVEL</h4>
                      <hr
                        style={{
                          background: '#000',
                          color: '#000',
                          borderColor: '#00',
                          height: '1px',
                        }}
                      />

                      <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="responsavelNome"
                            label="Nome"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="responsavelCpf"
                            label="CPF"
                            required={true}
                          />
                        </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="responsavelNascimento"
                            label="Data Nascimento"
                            type="date"
                            required={true}
                          />
                        </div>



                      </div>


                      <div className="row" style={{ marginTop: '20px' }}>


                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="responsavelTelefone"
                            label="Telefone"
                            required={true}
                          />
                        </div>





                      </div>

                      <h4 style={{ marginTop: '20px', marginBottom: '1px' }}>DADOS SOCIAIS</h4>
                      <hr
                        style={{
                          background: '#000',
                          color: '#000',
                          borderColor: '#00',
                          height: '1px',
                        }}
                      />

                      <div className="row" style={{ marginTop: '20px' }}>
                      <div className='col-md-4'>
                      <div className="form-group">
                        <label htmlFor="beneficiarioProgramaSocial">Beneficiário de Programas Socias</label>
                        <select className="form-select" id="beneficiarioProgramaSocial" {...register("beneficiarioProgramaSocial", { required: true, valueAsNumber: true })} defaultValue={0}>
                          <option value={0}></option>
                          <option value={1}>BOLSA FAMÍLIA</option>
                          <option value={2}>CADÚNICO</option>
                          <option value={3}>NÃO SOU BENEFICIÁRIO DE PROGRAMAS SOCIAIS</option>
                        </select>
                        
                      </div>
                    </div>

                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="nis"
                            label="NIS"
                            type="text"
                            required={false}
                          />
                        </div>


                        <div className="col-md-4">
                          <FormInput
                            register={register}
                            errors={errors}
                            atribute="identificacaoUnica"
                            label="Identificação Única"
                            type="text"
                            required={false}
                          />
                        </div>


                        </div>























                      <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: 0,
                        marginTop: 20
                      }}>
                        <button
                          style={{ marginRight: 20, marginTop: 20, height: 36 }}
                          type='button'
                          className='btn btn-warning'
                          onClick={() => navigate(-1)}>
                          Voltar
                        </button>
                        <button
                          type='submit'
                          className='btn btn-success'
                          style={{marginTop: '20px', display: ocultaBotaEnviar === true ? 'none' : 'initial'}}
                        // disabled={ocultaBotaEnviar}
                        >
                          Enviar
                        </button>
                      </div>
                    </div>


                    <div
                      className="tab-pane fade show"
                      id="historico"
                      role="tabpanel"
                      aria-labelledby="historico-tab"
                    >



                      <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-12">

                          <Table
                            columns={columnsUnidades}
                            data={historicoData?.data ? historicoData?.data : []}
                          />

                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </div>

          </div>

        </div>

      </form>
      </>
        )}

    </BaseComponent>

    
  )

}

export default AlunosAdicionarPage;