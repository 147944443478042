/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from 'react-redux';
import { Types as PanelTypes } from '../../store/ducks/panel';

const HeaderNotLogged = ({ title, isGestao, showPicker, docente, loadUnidades = true }) => {

  return (
    <div className="app-header">
      <nav className="navbar navbar-light navbar-expand-lg">
        <div className="container-fluid">
          <div className="navbar-nav" id="navbarNav">
            
          </div>

          <div className="d-flex">
            <ul className="navbar-nav">
             
  


            

              <li className="nav-item ">
                <a href='https://www.youtube.com/channel/UCM3JP1afY34zPF99DUrODBQ' className="nav-link nav-link">
                  <i className="material-icons-two-tone">play_circle</i> Suporte
                </a>
              </li>

              {/*LOGOUT*/}
              <li className="nav-item hidden-on-mobile">
                <a className="nav-link text-dark" href="/">
                  <i className="material-icons" style={{ paddingBottom: '2px', marginRight: '2px' }}>logout</i>
                  Sair
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav >
    </div >
  )
};

export default HeaderNotLogged;